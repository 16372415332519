import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import PostCards from '../components/PostCards';

const NewsPage = ({
  data: {
    datoCmsNewsArchive: { seoMetaTags, title, bannerImage },
    allDatoCmsNewsSingle: { nodes },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner title={title} image={bannerImage} />
        <PostCards
          items={nodes}
          baseSlug="/news/"
          flipCards={true}
          margins={true}
        />
      </main>
    </Layout>
  );
};

export const NewsPageQuery = graphql`
  query NewsPageQuery {
    datoCmsNewsArchive {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        fluid(
          maxHeight: 380
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
    allDatoCmsNewsSingle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 280
            maxWidth: 480
            imgixParams: { auto: "compress", fit: "crop", h: "280", w: "480" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        meta {
          firstPublishedAtText: firstPublishedAt(formatString: "DD MMMM YYYY")
          firstPublishedAtAttribute: firstPublishedAt(
            formatString: "YYYY-MM-DD"
          )
        }
      }
    }
  }
`;

export default NewsPage;
